<template>
  <div>
    <div class="navi-link" @click.stop="dialog = true">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
    </div>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">Hodim lavozim o'zgartirish</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="staffPos"
            :items="getStaffDepartments"
            outlined
            item-text="name"
            item-value="id"
            dense
            label="Hodim bo'limi"
          ></v-autocomplete>

          <v-text-field
            label="Bo'lim nomi "
            v-model="name"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            label="Oklad"
            v-model="oklad"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="dialog = false"> Bekor qilish </v-btn>

          <v-btn color="primary" @click="submit"> Yuborish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jjjj: '',
      dialog: false,
      staffPos: '',
      name: '',
      oklad: ''
    }
  },
  created() {
    this.$store.dispatch('staffDepartments')
    this.staffPos = this.staffData.department
    this.name = this.staffData.name
    this.oklad = this.staffData.oklad
  },
  computed: {
    getStaffDepartments() {
      return this.$store.getters.staffDepartments
    },
    getfff() {
      // console.log(this.$store.getters.staffDepartments.find(obj => obj.id == this.staffData.department))
      return this.$store.getters.staffDepartments.find(
        (obj) => obj.department == this.staffData.id
      )
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.name,
        oklad: this.oklad.replace(/,/g, '')
      }
      // if (this.staffPos.hasOwnProperty("id") == true) {
      data.department = this.staffPos
      // } else if (this.staffPos.hasOwnProperty("id") == false) {
      //   data.department = this.staffData.department;
      // }
      console.log(data)
      this.$store.dispatch('staffPositionUpdate', {
        id: this.staffData.id,
        data
      })
      this.dialog = false
    }
  },
  props: {
    staffData: {
      type: Object
    }
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
</style>
