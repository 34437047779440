<template>
  <div>
    <v-btn color="primary" small @click.stop="dialog = true">
      {{ $t('MENU.ENTERPRICE.STAFF.POSITION') }} +
    </v-btn>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Hodim lavozim</v-card-title>
        <v-card-text>
          <v-autocomplete
            :error-messages="valueErrors"
            v-model="value"
            :items="departments"
            outlined
            :success="Boolean(value)"
            item-text="name"
            item-value="id"
            dense
            label="Hodimlar Bo'limi"
            @input="$v.value.$touch()"
            @blur="$v.value.$touch()"
          ></v-autocomplete>
          <v-text-field
            :error-messages="nameErrors"
            :success="Boolean(name)"
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
            label="Bo'lim nomi "
            v-model="name"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :error-messages="okladErrors"
            :success="Boolean(oklad)"
            @input="$v.oklad.$touch()"
            @blur="$v.oklad.$touch()"
            label="Oklad"
            v-currency="options"
            v-model="oklad"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            :disabled="newstaffpositionLoading"
            @click="dialog = false"
          >
            Bekor qilish
          </v-btn>

          <v-btn
            :disabled="newstaffpositionLoading"
            color="primary"
            @click="submit"
          >
            <i v-if="newstaffpositionLoading" class="el-icon-loading"></i>
            Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      // okladMask
      newstaffpositionLoading: false,
      oklad: null,
      locale: 'default',
      distractionFree: false,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      allowNegative: false,
      // okladMask
      dialog: false,
      value: '',
      name: ''
    }
  },
  validations: {
    value: {
      required
    },
    name: {
      required
    },
    oklad: {
      required
    }
  },
  created() {
    this.$store.dispatch('staffDepartments')
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !this.$v.value.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    okladErrors() {
      const errors = []
      if (!this.$v.oklad.$dirty) return errors
      !this.$v.oklad.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    departments() {
      return this.$store.getters.staffDepartments
    },
    options() {
      return {
        locale: 'es-US',
        currency: [null],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          name: this.name,
          department: this.value,
          oklad: parseInt(this.oklad.split(',').join(''), 10)
        }
        this.newstaffpositionLoading = true
        this.$store
          .dispatch('staffPositionCreate', data)
          .then(() => {
            this.newstaffpositionLoading = false
            this.name = ''
            this.oklad = ''
            this.departments = ''
            this.dialog = false
          })
          .catch((err) => {
            this.newstaffpositionLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>

<style></style>
