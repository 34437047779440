<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.ENTERPRICE.STAFF.POSITION') }}
              </h3>
            </div>
            <div class="card-button">
              <create :data="getStaffDepartments" />
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :page.sync="page"
              single-select
              @click:row="rowClick"
              item-key="id"
              :items-per-page="itemsPerPage"
              :items="getStaffPositions"
              hide-default-footer
              class="row-pointer"
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getStaffPositions
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div style="text-align: right !important">
                  <action :index="item"></action>
                </div>
              </template>
            </v-data-table>
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="Math.ceil(this.getStaffPositions.length / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './action'
import create from './create'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action, create },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      page: 1,
      itemsPerPage: 10
    }
  },
  beforeCreate() {
    this.$store.dispatch('staffDepartments')
    this.$store.dispatch('staffPositions')
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.STAFF_POSITION'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.STAFF_DEPARTMENT'),
          value: 'department_name'
        },
        {
          text: this.$t('TABLE_HEADER.STAFF_OKLAD'),
          value: 'oklad'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getStaffPositions() {
      return this.$store.getters.staffPositions
    },
    getStaffDepartments() {
      return this.$store.getters.staffDepartments
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0

        row.select(false)
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.STAFF.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.STAFF.POSITION') }
    ])
  }
}
</script>

<style></style>
